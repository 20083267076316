<template>
  <v-container fluid>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="8" md="6" lg="4">
        <v-card v-if="klinik" class="mx-auto my-10" elevation="8">
          <v-img height="250" src="../assets/RB-logo.png" :alt="klinik.klinikNamn">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          
          <v-card-title class="headline">{{ klinik.klinikNamn }}</v-card-title>
          
          <v-card-text>
            <v-list>
              <v-list-item v-for="(value, key) in klinikDetails" :key="key">
                <v-list-item-icon>
                  <v-icon>{{ value.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ value.label }}</v-list-item-title>
                  <v-list-item-subtitle>{{ klinik[key] }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>

        <v-skeleton-loader
          v-else-if="loading"
          type="card"
          class="mx-auto my-10"
        ></v-skeleton-loader>

        <v-alert v-else-if="error" type="error" class="mx-auto my-10">
          Could not load clinic data. Please try again later.
        </v-alert>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "Klinik",
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      klinik: null,
      loading: true,
      error: null,
      snackbar: {
        show: false,
        text: '',
        color: '',
        timeout: 3000
      },
      klinikDetails: {
        region: { label: 'Region', icon: 'mdi-map' },
        ort: { label: 'Ort', icon: 'mdi-city' },
        klinikAdress: { label: 'Adress', icon: 'mdi-home' },
        klinikTelefon: { label: 'Telefon', icon: 'mdi-phone' },
        vTid: { label: 'Väntetid', icon: 'mdi-clock-outline' }
      }
    };
  },
  methods: {
    async getKlinikData() {
      this.loading = true;
      try {
        const response = await this.$store.dispatch('getKlinikById', this.id);
        this.klinik = response;
        this.showSnackbar('Klinikdata laddad', 'success');
      } catch (error) {
        console.error('Error fetching clinic details:', error);
        this.error = error;
        this.showSnackbar('Kunde inte ladda klinikdata', 'error');
      } finally {
        this.loading = false;
      }
    },
    showSnackbar(text, color = 'info') {
      this.snackbar.text = text;
      this.snackbar.color = color;
      this.snackbar.show = true;
    }
  },
  mounted() {
    this.getKlinikData();
  },
};
</script>

<style scoped>
.v-card__title {
  word-break: break-word;
}
</style>